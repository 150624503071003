import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showSuccess(message: string, title?: string) {
    this.toastr.success(message, title ? title : 'Success', {
      closeButton: true,
      tapToDismiss: false,
      positionClass: 'toast-top-right'
    });
  }

  showSuccessWithTimeout(message: string, title: string, timespan: number) {
    this.toastr.success(message, title, {
      timeOut: timespan,
      tapToDismiss: false,
      closeButton: true,
      positionClass: 'toast-top-right'
    });
  }

  showError(message: string, title?: string) {
    this.toastr.error(message, title ? title : 'Error', {
      closeButton: true,
      tapToDismiss: false,
      disableTimeOut: true,
      positionClass: 'toast-top-full-width'
    });
  }

  showWarning(message: string, title?: string) {
    this.toastr.warning(message, title, {
      closeButton: true,
      tapToDismiss: false,
      disableTimeOut: true,
      positionClass: 'toast-top-full-width'
    });
  }

  showErrorWithTimeout(message: string, title: string, timespan: number) {
    this.toastr.error(message, title, {
      timeOut: timespan,
      closeButton: true,
      tapToDismiss: false,
      positionClass: 'toast-top-full-width'
    });
  }

  showHTMLMessageNotification(message: string, title: string) {
    return this.toastr.warning(message, title, {
      enableHtml: true,
      closeButton: true,
      tapToDismiss: false,
      disableTimeOut: true,
      positionClass: 'toast-top-full-width'
    });
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { CustomPreloadingService } from './core/services/custom-preloading/custom-preloading.service';


const routes: Routes = [
  {
    path: '',
    component: PageNotFoundComponent,
    pathMatch: 'full'
  },
  {
    path: 'form/:id', data: { preload: true }, loadChildren: () => import('src/app/modules/external-form/external-form.module').then(m => m.ExternalFormModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingService })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CustomPreloadingService } from './services/custom-preloading/custom-preloading.service';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { NotificationService } from './services/common/notification/notification.service';

@NgModule({
    declarations: [],
    imports: [
        HttpClientModule,
        ToastNoAnimationModule.forRoot({
            preventDuplicates: true,
        })
    ],
    exports: [
        HttpClientModule,
    ],
    providers: [
        CustomPreloadingService,
        NotificationService
    ],
})
export class CoreModule { }
